@charset "UTF-8";

.page-about {
  .main {
    .for_customers {
      padding-top: 75px;
      padding-bottom: 60px;
      @include tab {
        padding-top: 50px;
        padding-bottom: 50px;
      }
      @include sp {
        padding-top: 40px;
        padding-bottom: 40px;
      }
      h2 {
        font-size: 2rem;
        margin-bottom: 35px;
        @include sp {
          margin-bottom: 30px;
        }
      }
      .lead {
      }
    }
    .service {
    	.items {
    		.item {
          &:nth-of-type(1) .image {
            background-image: url('../images/about_service01.jpg');
            @include sp {
              background-image: url('../images/sp_about_service01.jpg');
            }
          }
          &:nth-of-type(2) .image {
            background-image: url('../images/about_service02.jpg');
            @include sp {
              background-image: url('../images/sp_about_service02.jpg');
            }
          }
          &:nth-of-type(3) .image {
            background-image: url('../images/about_service03.jpg');
            @include sp {
              background-image: url('../images/sp_about_service03.jpg');
            }
          }
          &:nth-of-type(2n+1) {
            background-color: #044565;
            .image {
              float: left;
              @include sp {
                float: none;
              }
            }
            .text {
              left: 34%;
              @include tab {
                left: 32.5%;
              }
            }
          }
          &:nth-of-type(2n) {
            background-color: #002E44;
            .image {
              float: right;
              @include sp {
                float: none;
              }
            }
            .text {
              left: 0;
              @include tab {
                left: 1.5%;
              }
              @include sp {
                left: auto;
              }
            }
          }
          .inner {
            @include row;
            position: relative;
            @include tab {
              padding-left: 0;
              padding-right: 0;
            }
            @include sp {
              position: static;
              padding-left: 0;
              padding-right: 0;
            }
          }
  				.image {
            width: 30%;
            height: 0;
            padding-top: 28%;
            background-repeat: no-repeat;
            background-position: 0 0;
            background-size: cover;
            @include tab {
              width: 30%;
              padding-top: 28%;
            }
            @include sp {
              width: 100%;
              padding-top: 63.46%;
            }
  				}
  				.text {
            position: absolute;
            width: 66%;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            @include sp {
              position: static;
              width: 100%;
              top: auto;
              -webkit-transform: none;
              transform: none;
              padding: 40px 35px;
            }
  					h3 {
              color: #EEE;
              font-size: 1.429rem;
              margin-bottom: 10px;
              @include sp {
                margin-bottom: 25px;
                text-align: center;
              }
  					}
  					p {
              color: #FFF;
              font-size: 1rem;
              line-height: 2.143;
              @include tab {
                line-height: 1.5;
              }
  					}
  				}
    		}
    	}
    }
    .chief {
      margin-top: -88px;
      padding-top: 170px;
      // padding-top: 80px;
      padding-bottom: 100px;
      @include tab {
        margin-top: -58px;
        padding-top: 120px;
        // padding-top: 60px;
        padding-bottom: 70px;
      }
      @include sp {
        margin-top: -58px;
        padding-top: 100px;
        // padding-top: 40px;
        padding-bottom: 40px;
      }
  		h2 {
        margin-bottom: 60px;
        @include sp {
          margin-bottom: 30px;
        }
  		}
      .inner {
        @include row;
        max-width: 1240px;
      }
  		.image {
  			float: left;
        @include sp_tab {
          display: none;
        }
  		}
  		.text {
        float: left;
        margin-left: 30px;
        @include tab {
          float: none;
          width: 100%;
          margin-left: 0;
          padding-left: 55px;
        }
        @include sp {
          float: none;
          margin-left: 0;
        }
  			h3 {
          font-size: 1.143rem;
          font-weight: bold;
          margin-bottom: 15px;
          @include sp {
            font-size: 1.071rem;
          }
  			}
  			p {
          font-size: 1rem;
          line-height: 2rem;
          @include sp {
            font-size: 1.071rem;
          }
          &:not(:last-of-type) {
            margin-bottom: 15px;
          }
  			}
  		}
    }
    .company {
      padding-top: 0;
      padding-bottom: 100px;
      @include tab {
        padding-bottom: 70px;
      }
      @include sp {
        padding-bottom: 40px;
      }
  		h2 {
        margin-bottom: 60px;
        @include sp {
          margin-bottom: 30px;
        }
  		}
      a {
        color: #000;
      }
  		table {
        width: 100%;
        max-width: 840px;
        margin: 0 auto;
        border-collapse: collapse;
				tr {
          &:nth-of-type(2n+1) {
            background-color: #F5F5F5;
          }
					th {
            padding: 15px 120px 15px 55px;
            font-size: 1rem;
            font-weight: bold;
            line-height: 1.4;
            text-align: left;
            white-space: nowrap;
            @include sp {
              display: block;
              padding: 20px 16px 12px;
            }
					}
					td {
            width: 100%;
            padding: 15px 0;
            font-size: 1rem;
            line-height: 1.4;
            text-align: left;
            @include sp {
              display: block;
              padding: 0 16px 24px;
            }
					}
				}
  		}
    }
  }
}
