@charset "UTF-8";

.page-service {
  .main {
    .about {
      padding-top: 40px;
      padding-bottom: 40px;
  		p {
        color: #000;
        font-size: 1.071rem;
        line-height: 2;
        text-align: center;
        @include sp {
          text-align: left;
        }
  		}
    }
    .service {
      padding-top: 80px;
      padding-bottom: 80px;
      background-image: url('../images/bg_service.png');
      background-repeat: no-repeat;
      background-position: 50% 0;
      background-size: cover;
      @include tab {
        padding-top: 50px;
        padding-bottom: 50px;
      }
      @include sp {
        padding-top: 40px;
        padding-bottom: 40px;
      }
      h2 {
        color: #FFF;
        @include sp {
          margin-bottom: 40px;
        }
      }
      .lead {
        margin-bottom: 50px;
        color: #FFF;
        @include sp {
          margin-bottom: 40px;
        }
      }
      .items_wrapper {
        margin-left: -5px;
        margin-right: -5px;
        @include sp {
          margin-left: 0;
          margin-right: 0;
          padding-left: 15px;
          padding-right: 15px;
        }
      }
      .items {
        display: table;
        table-layout: fixed;
        border-collapse: separate;
        border-spacing: 5px 0;
        width: 100%;
        @include sp {
          display: block;
          border-spacing: 0 0;
          margin-bottom: 0;
        }
        .item {
          display: table-cell;
          background-color: #FFF;
          padding-bottom: 35px;
          @include sp {
            display: block;
            padding-bottom: 30px;
            &:not(:last-of-type) {
              margin-bottom: 40px;
            }
          }
          .image {
            margin-bottom: 30px;
          }
          h3 {
            color: #000;
            font-size: 1.071rem;
            font-weight: bold;
            line-height: 2;
            text-align: center;
            margin-bottom: 10px;
            @include sp {
              font-size: 1rem;
            }
            small {
              display: block;
              color: $gold;
              font-size: 0.929rem;
              font-weight: normal;
              line-height: 1.846;
              margin-bottom: 5px;
              @include sp {
                font-size: 0.857rem;
              }
            }
          }
          p {
            color: #666;
            font-size: 0.857rem;
            line-height: 1.667;
            text-align: center;
            margin-bottom: 35px;
            @include sp {
              margin-bottom: 25px;
              font-size: 0.786rem;
            }
          }
          h4 {
            position: relative;
            display: table;
            margin: 0 auto;
            color: $gold;
            font-size: 0.929rem;
            line-height: 1;
            padding-left: 1.8em;
            padding-right: 1.8em;
            margin-bottom: 20px;
            text-align: center;
            &:before, &:after {
              content: "";
              display: block;
              position: absolute;
              width: 1.5em;
              height: 1px;
              background-color: $gold;
              top: 50%;
            }
            &:before {
              left: 0;
            }
            &:after {
              right: 0;
            }
          }
          ul {
            padding-left: 30px;
            padding-right: 20px;
            li {
              color: #000;
              font-size: 0.929rem;
              line-height: 2;
              text-indent: -1em;
              @include sp {
                font-size: 0.786rem;
              }
              &:before {
                content: "・";
              }
            }
          }
        }
      }
    }
    .flow {
      padding-top: 80px;
      padding-bottom: 80px;
      @include tab {
        padding-top: 50px;
        padding-bottom: 50px;
      }
      @include sp {
        padding-top: 40px;
        padding-bottom: 40px;
      }
  		h2 {
        margin-bottom: 60px;
        @include tab {
          margin-bottom: 50px;
        }
        @include sp {
          margin-bottom: 30px;
        }
  		}
  		.image {
  			img {
          margin: 0 auto;
  			}
  		}
    }
    .method {
      padding-bottom: 80px;
      @include tab {
        padding-bottom: 50px;
      }
      @include sp {
        padding-bottom: 40px;
      }
  		h2 {
        margin-bottom: 60px;
        @include tab {
          margin-bottom: 40px;
        }
        @include sp {
          margin-bottom: 30px;
        }
  		}
  		.text {
  			p {
          color: #000;
          font-size: 1rem;
          line-height: 2.14;
          text-align: center;
          @include sp {
            font-size: 1.071rem;
            line-height: 2;
            text-align: left;
          }
  			}
  		}
    }
    .software {
      padding-bottom: 80px;
      @include tab {
        padding-bottom: 50px;
      }
      @include sp {
        padding-bottom: 40px;
      }
  		h2 {
        margin-bottom: 60px;
        @include tab {
          margin-bottom: 40px;
        }
        @include sp {
          margin-bottom: 30px;
        }
  		}
  		.items {
        max-width: 840px;
        margin: 0 auto;
  			.item {
          padding: 25px 30px 20px;
          @include sp {
            padding: 20px 20px;
          }
          &:nth-of-type(2n+1) {
            background-color: #F6F6F6;
          }
  				h3 {
            color: #000;
            font-size: 1rem;
            font-weight: bold;
            line-height: 2;
            margin-bottom: 8px;
  				}
  				p {
            color: #4B4B4B;
            font-size: 1rem;
            line-height: 2;
  				}
  			}
  		}
    }
  }
}
