@charset "UTF-8";
header {
  display: block;
  width: 100%;
  height: auto;
  padding-top: 90px;
  @include sp_tab {
    padding-top: 60px;
  }
  * {
    @include kerning;
  }
  .bar {
    @include row;
    position: fixed;
    z-index: 1002;
    top: 0;
    width: 100%;
    height: 90px;
    background-color: #FFF;
    border-bottom: 1px solid #DFDFDF;
    @include sp_tab {
      height: 60px;
    }
    .logo {
      float: left;
      padding: 22px 0 22px 40px;
      @include sp_tab {
        padding: 17px 0 17px 20px;
      }
      img {
        height: 45px;
        @include sp_tab {
          height: 28px;
        }
      }
    }
    .sp_mailform, .sp_menu, .sp_tel {
      display: none;
    }
    .sp_menu {
      @include sp_tab {
        float: right;
        display: block;
        a {
          position: relative;
          display: block;
          width: 60px;
          height: 60px;
          background-color: $gray_indigo;
          &:after, &:before, button {
            position: absolute;
            display: block;
            width: 18px;
            height: 2px;
            left: 22px;
            background-color: #FFF;
          }
          &:before {
            content: "";
            top: 23px;
          }
          &:after {
            content: "";
            top: 29px;
          }
          button {
            top: 35px;
          }
        }
      }
    }
    .gnav {
      float: right;
      @include sp_tab {
        display: none;
        position: fixed;
        left: 0;
        top: 0;
        float: none;
        width: 100%;
        height: 100%;
        min-height: 100%;
        padding-top: 66px;
        background-color: #131F26;
      }
      .sp_gnav_close {
        display: none;
        @include sp_tab {
          position: fixed;
          display: block;
          top: 16px;
          right: 15px;
          width: 30px;
          height: 30px;
          &:after,           &:before {
            content: "";
            position: fixed;
            display: block;
            width: 30px;
            height: 1px;
            right: 15px;
            top: 31px;
            background-color: #FFF;
            -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
          }
          &:before {
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
          }
          &:after {
            -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
          }
        }
      }
      .sp_tel {
        display: none;
        @include sp_tab {
          display: block;
          margin: 50px auto 0;
          color: #CCC;
          font-size: 1.714rem;
          font-weight: 700;
          line-height: 1;
          text-align: center;
        }
      }
      .sp_mailform {
        display: none;
        @include sp_tab {
          display: block;
          margin: 45px auto 0;
          width: 210px;
          height: 40px;
          background-color: #002E44;
          color: #ccc;
          font-size: 1rem;
          line-height: 40px;
          border-radius: 20px;
          text-align: center;
        }
      }
      ul {
        display: table;
        @include sp_tab {
           @include row;
          display: block;
          border-top: 1px solid #293238;
        }
        li {
          display: table-cell;
          vertical-align: middle;
          @include sp_tab {
            float: left;
            display: block;
            width: 50%;
            &:nth-of-type(2n+1) {
              a {
                border-right: 1px solid #293238;
              }
            }
          }
          a {
            display: block;
            vertical-align: middle;
            height: 90px;
            line-height: 90px;
            font-size: 0.93rem;
            padding: 0 25px;
            font-weight: bold;
            color: #000;
            text-align: center;
            &:hover {
              background-color: #E9E9E9;
              @include sp_tab {
                background-color: transparent;
              }
            }
            &.contact {
              background-color: $indigo;
              @include sp_tab {
                background-color: transparent;
              }
              color: #FFF;
              &:hover {
                background-color: #075074;
                @include sp_tab {
                  background-color: transparent;
                }
              }
            }
            @include sp_tab {
              height: auto;
              line-height: 1;
              padding: 22px 0;
              font-size: 1rem;
              color: #FFF;
              border-bottom: 1px solid #293238;
            }
          }
        }
      }
    }
  }
  .about {
    @include row;
    height: 60px;
    line-height: 60px;
    background-color: #F2F2F2;
    padding-right: 40px;
    @include sp_tab {
      display: none;
    }
    p {
      float: right;
      font-size: 0.86rem;
      color: #666;
      padding-right: 25px;
    }
    .tel {
      float: right;
      color: $indigo;
      font-size: 1.6rem;
      font-weight: 700;
    }
  }
  .pagetitle {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 22.58%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    @include sp {
      padding-top: 37.33%;
    }
    h1 {
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%,-50%);
      transform: translate(-50%,-50%);
      font-size: 2rem;
      font-weight: normal;
      line-height: 1;
      color: #FFF;
      text-align: center;
      white-space: nowrap;
      @include sp {
        font-size: 1.643rem;
      }
      small {
        display: block;
        margin-top: 10px;
        font-size: 1rem;
        @include sp {
          margin-top: 8px;
          font-size: 0.857rem;
        }
      }
    }
  }
  .mainvisual {
    z-index: 1000;
    .slides {
      .slide {
        width: 100%;
        height: 520px;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: cover;
        @include sp {
          height: 0;
          padding-top: 83.33%;
        }
        @include tab {
          height: 0;
          padding-top: 42%;
        }
        .text_wrapper {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
        }
        .text {
          color: #FFF;
          width: 1000px;
          margin: 160px auto 0;
          text-align: left;
          @include sp_tab {
            position: absolute;
            width: auto;
            margin: 0;
            left: 20px;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
          }
          h2 {
            font-size: 2.8rem;
            line-height: 1.3;
            font-weight: normal;
            margin-bottom: 5px;
            @include sp {
              font-size: 1.643rem;
            }
            @include tab {
              font-size: 2.2rem;
            }
          }
          p {
            font-size: 1.6rem;
            line-height: 1.7;
            @include sp {
              font-size: 0.929rem;
            }
            @include tab {
              font-size: 1.3rem;
            }
          }
        }
      }
    }
  }
}
