@charset "UTF-8";

h1, h2, h3, h4, h5, h6 {
  @include kerning;
}
li {
  list-style-type: none;
  list-style-position: inside;
}
a {
  text-decoration: none;
  transition: all 0.1s linear;
}
em {
  font-style: normal;
}
img {
  display: block;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}
.inner {
  width: 100%;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  @include tab {
    padding-left: 20px;
    padding-right: 20px;
  }
  @include sp {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
}
.main, footer {
  h2 {
    margin-bottom: 35px;
    font-size: 2rem;
    font-weight: normal;
    line-height: 1;
    text-align: center;
    color: $gray_indigo;
    @include sp {
      font-size: 1.429rem;
      margin-bottom: 15px;
    }
    small {
      display: block;
      margin-top: 10px;
      font-size: 0.857rem;
    }
  }
  .lead {
    p {
      font-size: 1.071rem;
      line-height: 2;
      text-align: center;
    }
  }
}
.bx-wrapper {
  margin: 0 auto;
  .bx-viewport {
    box-shadow: none;
    border: none;
    left: 0;
  }
}
.maps {
  label {
    width: auto;
    display: inline;
  }
  img {
    max-width: none;
    max-height: none;
  }
}
.wp-pagenavi {
  .pages, .page, .current, .first, .last, .extend {
    display: none;
  }
}
