@charset "UTF-8";

footer {
  @include row;
  position: relative;
  .contact {
    clear: both;
    padding-top: 70px;
    padding-bottom: 70px;
    background-image: url('../images/footer_contact.jpg');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    @include tab {
      padding-top: 55px;
      padding-bottom: 50px;
    }
    @include sp {
      padding-top: 40px;
      padding-bottom: 45px;
      background-image: url('../images/sp_footer_contact.jpg');
      background-position: 50% 0;
    }
    h2 {
      color: #FFF;
      @include sp {
        margin-bottom: 25px;
      }
    }
    .lead {
      margin-bottom: 35px;
      color: #FFF;
    }
    .items {
      display: table;
      margin-left: auto;
      margin-right: auto;
      .item {
        display: table-cell;
        vertical-align: middle;
        &:first-of-type {
          padding-right: 60px;
          @include sp {
            padding-right: 30px;
          }
        }
        .tel {
          color: #CCC;
          font-size: 2rem;
          font-weight: 700;
          text-align: left;
          line-height: 1;
          pointer-events: none;
          @include sp {
            pointer-events: auto;
            font-size: 1.429rem;
          }
        }
        .mailform {
          display: inline-block;
          width: 190px;
          height: 48px;
          line-height: 48px;
          border-radius: 24px;
          font-size: 0.929rem;
          text-align: center;
          background-color: #032E43;
          color: #CCC;
          @include sp {
            width: 155px;
            height: 36px;
            line-height: 36px;
            border-radius: 18px;
            font-size: 0.857rem;
          }
          &:hover {
            background-color: #0C5377;
            color: #FFF;
          }
        }
      }
    }
  }
  .foot {
    padding-top: 45px;
    padding-bottom: 25px;
    background-color: #141819;
    @include sp {
      padding-bottom: 40px;
    }
    .company {
      float: left;
      text-align: left;
      @include sp {
        float: none;
        margin-bottom: 30px;
        text-align: center;
      }
      h2 {
        color: #FFF;
        font-size: 1.071rem;
        margin-bottom: 10px;
        text-align: left;
        @include sp {
          text-align: center;
          font-size: 1rem;
        }
      }
      .address {
        color: #999;
        font-size: 0.857rem;
        @include sp {
          font-size: 0.714rem;
        }
      }
    }
    .gnav {
      float: right;
      @include sp {
        float: none;
      }
      ul {
        display: table;
        @include sp {
          display: table;
          margin-left: auto;
          margin-right: auto;
          text-align: center;
        }
        li {
          display: table-cell;
          @include sp {
            display: inline-block;
            padding-right: 40px;
            &:nth-of-type(3), &:nth-of-type(5) {
              padding-right: 0;
            }
            &:nth-of-type(4) {
              margin-top: 15px;
            }
          }
          @include tab_pc {
            &:not(:last-of-type) {
              padding-right: 40px;
            }
          }
          a {
            color: #FFF;
            font-size: 0.929rem;
            white-space: nowrap;
            &:hover {
              color: #CCC;
            }
          }
        }
      }
    }
    .copyright {
      clear: both;
      padding-top: 50px;
      text-align: left;
      @include sp {
        padding-top: 30px;
        text-align: center;
      }
      p {
        color: #999;
        font-size: 0.857rem;
      }
    }
  }
}
.scroll_top {
  position: fixed;
  display: none;
  width: 100%;
  bottom: 30px;
  @include sp {
    bottom: 10px;
  }
  &.footer {
    position: absolute;
    top: 0;
    bottom: auto;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  a {
    float: right;
    display: block;
    width: 54px;
    height: 54px;
    background-image: url('../images/scroll_top.svg');
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: cover;
    @include sp {
      width: 36px;
      height: 36px;
    }
    &:hover {
      background-image: url('../images/scroll_top_hover.svg');
    }
  }
}
