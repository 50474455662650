@charset "UTF-8";

.page-analysis, .page-works {
  .main {
    .works {
      padding-top: 80px;
      padding-bottom: 50px;
      @include tab {
        padding-top: 50px;
        padding-bottom: 30px;
      }
      @include sp {
        padding-top: 40px;
        padding-bottom: 20px;
      }
      .links {
        margin-bottom: 60px;
        @include tab {
          margin-bottom: 50px;
        }
        @include sp {
          margin-bottom: 40px;
        }
        ul {
          display: table;
          margin-left: auto;
          margin-right: auto;
          li {
            float: left;
            &:not(:last-of-type) {
              margin-right: 20px;
            }
            a {
              display: block;
              width: 240px;
              height: 42px;
              line-height: 40px;
              border-radius: 21px;
              border: 1px solid #0A4664;
              background-color: #FFF;
              color: #0A4664;
              text-align: center;
              @include sp {
                font-size: 0.857rem;
                width: 155px;
              }
              &:hover {
                background-color: #044565;
                border-color: #044565;
                color: #FFF;
              }
            }
          }
        }
      }
      h2 {
        margin-bottom: 40px;
        @include sp {
          margin-bottom: 30px;
        }
      }
      .note {
        margin-bottom: 15px;
        padding-left: 1em;
        text-indent: -1em;
        @include sp {
          @include kerning;
          margin-bottom: 30px;
        }
        p {
          font-size: 1rem;
          line-height: 2;
          @include sp {
            font-size: 1.071rem;
          }
          a {
            color: #000;
            text-decoration: underline;
            &:hover {
              text-decoration: none;
              color: #666;
            }
          }
        }
      }
      .items {
        &:not(:last-of-type) {
          margin-bottom: 30px;
          @include sp {
            margin-bottom: 20px;
          }
        }
        .head {
          margin-bottom: 30px;
          h3 {
            background-color: #E6EDF1;
            color: #0A4664;
            font-size: 1.143rem;
            font-weight: bold;
            padding: 15px 22px;
            @include sp {
              padding: 18px 20px;
            }
          }
          p {
            padding-top: 20px;
            font-size: 1rem;
            line-height: 2;
            @include sp {
              padding-top: 30px;
            }
          }
        }
        .item {
          h4 {
            background-color: #F5F5F5;
            border-left: 6px solid #044565;
            font-size: 1rem;
            font-weight: bold;
            padding: 12px 27px;
          }
          .text {
            font-size: 1rem;
            line-height: 2rem;
            padding: 20px 0 30px;
            @include sp {
              padding: 30px 0 40px;
            }
          }
        }
      }
    }
  }
}
