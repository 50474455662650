@charset "UTF-8";

.blog, .category, .single-post {
  .main {
    padding-top: 80px;
    padding-bottom: 80px;
    @include tab {
      padding-top: 60px;
      padding-bottom: 60px;
    }
    @include sp {
      padding-top: 30px;
      padding-bottom: 40px;
    }
    .categories {
      margin-bottom: 60px;
      @include tab {
        margin-bottom: 50px;
      }
      @include sp {
        margin-bottom: 30px;
      }
  		ul {
        display: table;
        margin: 0 auto;
        @include tab_pc {
          table-layout: fixed;
        }
        @include sp {
          @include row;
          width: 100%;
          margin-bottom: -5%;
        }
  			li {
          @include tab_pc {
            display: table-cell;
          }
          @include pc {
            &:not(:last-of-type) {
              padding-right: 40px;
            }
          }
          @include tab {
            &:not(:last-of-type) {
              padding-right: 25px;
            }
          }
          @include sp {
            float: left;
            display: block;
            width: 30%;
            margin-right: 5%;
            margin-bottom: 5%;
            &:nth-of-type(3n) {
              margin-right: 0;
            }
            &:nth-of-type(3n+1) {
              clear: both;
            }
          }
  				a {
            display: block;
            width: 140px;
            height: 42px;
            line-height: 40px;
            border-radius: 21px;
            border: 1px solid #0A4664;
            background-color: #FFF;
            color: #0A4664;
            font-size: 1rem;
            text-align: center;
            @include tab {
              width: 100px;
            }
            @include sp {
              width: 100%;
              font-size: 0.857rem;
            }
            &:hover {
              background-color: #0A4664;
              color: #FFF;
            }
  				}
  			}
  		}
    }
    .posts_nav {
      @include row;
      .previouspostslink, .nextpostslink {
        position: relative;
        display: block;
        width: 140px;
        height: 42px;
        line-height: 40px;
        border-radius: 21px;
        border: 1px solid $gold;
        background-color: #FFF;
        color: $gold;
        font-size: 1rem;
        text-align: center;
        @include tab {
          width: 120px;
        }
        @include sp {
          width: 100px;
        }
        &:hover {
          background-color: $gold;
          color: #FFF;
        }
        &:after {
          position: absolute;
          display: block;
          top: 50%;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
        }
      }
    	.previouspostslink {
        float: left;
        &:after {
          content: "\00AB";
          left: 0.8rem;
        }
    	}
    	.nextpostslink {
        float: right;
        &:after {
          content: "\00BB";
          right: 0.8rem;
        }
    	}
    }
  }
}

.blog, .category {
  .main {
    .categories {
      margin-bottom: 60px;
      @include sp {
        margin-bottom: 30px;
      }
    }
    .entries {
      table {
        width: 100%;
        border-top: 1px solid #E5E5E5;
        tr {
          th {
            padding: 20px 0;
            border-bottom: 1px solid #E5E5E5;
            white-space: nowrap;
            vertical-align: top;
            text-align: left;
            @include sp {
              display: block;
              border-bottom: none;
              padding-bottom: 15px;
            }
            .date {
              margin: 0;
              color: #333;
              font-size: 1rem;
              font-weight: 400;
              line-height: 26px;
              @include sp {
                line-height: 23px;
              }
            }
            .category {
              display: inline-block;
              margin: 0 15px;
              width: 100px;
              height: 26px;
              line-height: 26px;
              font-size: 0.857rem;
              text-align: center;
              background-color: $indigo;
              color: #FFF;
              @include sp {
                width: 90px;
                height: 23px;
                line-height: 23px;
                font-size: 0.786rem;
              }
            }
          }
          td {
            width: 100%;
            padding: 20px 0;
            border-bottom: 1px solid #E5E5E5;
            vertical-align: top;
            font-size: 1.071rem;
            line-height: 26px;
            text-align: left;
            @include sp {
              display: block;
              font-size: 1rem;
              line-height: 1.75;
              padding-top: 0;
            }
            a {
              color: #333;
              &:hover {
                color: #666;
              }
            }
          }
        }
      }
    }
    .posts_nav {
      margin-top: 60px;
      @include tab {
        margin-top: 50px;
      }
      @include sp {
        margin-top: 40px;
      }
    }
  }
}

.single-post {
  .main {
    .categories {
      margin-bottom: 60px;
      @include sp {
        margin-bottom: 40px;
      }
    }
    .entry {
      clear: both;
      .inner {
        width: 100%;
        max-width: 840px;
        @include sp_tab {
          max-width: 100%;
        }
      }
  		.head {
        padding-bottom: 20px;
        margin-bottom: 25px;
        border-bottom: 1px solid #E5E5E5;
  			.meta {
          margin-bottom: 12px;
          .date {
            margin: 0;
            color: #333;
            font-size: 1rem;
            font-weight: 400;
            line-height: 26px;
          }
          .category {
            display: inline-block;
            margin: 0 15px;
            width: 100px;
            height: 26px;
            line-height: 26px;
            font-size: 0.857rem;
            text-align: center;
            background-color: $indigo;
            color: #FFF;
            @include sp {
              width: 90px;
              height: 23px;
              line-height: 23px;
              font-size: 0.786rem;
            }
          }
  			}
  			h2 {
          color: #333;
          font-size: 1.429rem;
          line-height: 1.4;
          text-align: left;
          margin-bottom: 0;
          @include sp {
            font-size: 1rem;
            line-height: 1.75;
          }
  			}
  		}
  		.body {
  			p {
          color: #333;
          font-size: 1rem;
          line-height: 2;
  			}
  			img {
          margin: 2rem auto;
          max-width: 600px;
          @include tab {
            max-width: 450px;
          }
          @include sp {
            max-width: 300px;
          }
  			}
  		}
    }
    .posts_nav {
      margin-top: 75px;
      @include tab {
        margin-top: 50px;
      }
      @include sp {
        margin-top: 40px;
      }
    }
  }
}
