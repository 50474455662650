@charset "UTF-8";

@import "bourbon/bourbon";
@import "neat/neat";

@import "config";
@import "common";

@import "header";
@import "footer";

@import "home";
@import "blog";

@import "page-about";
@import "page-company";
@import "page-contact";
@import "page-service";
@import "page-works";

body, html {
  margin: 0;
  padding: 0;
  font-family: "Lato", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  background-color: #FFF;
  color: #000;
  font-size: 14px;
}
body {
  @include sp {
    * {
      -webkit-tap-highlight-color: transparent;
      outline: none;
    }
  }
}
