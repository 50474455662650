@charset "UTF-8";
.home {
  .main {
    .readmore {
      display: block;
      margin: 0 auto;
      border: 1px solid $gold;
      background-color: #FFF;
      width: 260px;
      height: 50px;
      border-radius: 25px;
      line-height: 48px;
      color: $gold;
      font-weight: 400;
      text-align: center;
      @include sp {
        width: 240px;
        height: 44px;
        line-height: 42px;
      }
      &:hover {
        background-color: $gold;
        color: #FFF;
      }
    }
    .for_customers {
      padding-top: 75px;
      padding-bottom: 80px;
      @include tab {
        padding-top: 50px;
        padding-bottom: 50px;
      }
      @include sp {
        padding-top: 40px;
        padding-bottom: 40px;
      }
      h2 {
        font-size: 2.143rem;
        @include sp {
          font-size: 1.643rem;
        }
      }
      .lead {
        margin-bottom: 60px;
        color: #000;
        @include tab {
          margin-bottom: 40px;
        }
        @include sp {
          text-align: center;
          margin-bottom: 40px;
        }
      }
    }
    .service {
      padding-top: 80px;
      padding-bottom: 80px;
      background-image: url('../images/bg_service.png');
      background-repeat: no-repeat;
      background-position: 50% 0;
      background-size: cover;
      @include tab {
        padding-top: 50px;
        padding-bottom: 50px;
      }
      @include sp {
        padding-top: 40px;
        padding-bottom: 50px;
      }
      h2 {
        color: #FFF;
        @include sp {
          margin-bottom: 40px;
        }
      }
      .lead {
        margin-bottom: 50px;
        @include sp {
          margin-bottom: 40px;
        }
        p {
          text-align: center;
          color: #FFF;
          @include sp {
            text-align: left;
          }
        }
      }
      .items_wrapper {
        margin-left: -5px;
        margin-right: -5px;
        @include sp {
          margin-left: 0;
          margin-right: 0;
          padding-left: 15px;
          padding-right: 15px;
        }
      }
      .items {
        display: table;
        table-layout: fixed;
        border-collapse: separate;
        border-spacing: 5px 0;
        width: 100%;
        margin-bottom: 60px;
        @include tab {
          margin-bottom: 50px;
        }
        @include sp {
          display: block;
          border-spacing: 0 0;
          margin-bottom: 0;
        }
        .item {
          display: table-cell;
          background-color: #FFF;
          padding-bottom: 35px;
          @include sp {
            display: block;
            margin-bottom: 40px;
            padding-bottom: 30px;
          }
          .image {
            margin-bottom: 30px;
          }
          h3 {
            color: #000;
            font-size: 1.071rem;
            font-weight: bold;
            line-height: 2;
            text-align: center;
            margin-bottom: 10px;
            @include sp {
              font-size: 1rem;
            }
            small {
              display: block;
              color: $gold;
              font-size: 0.929rem;
              font-weight: normal;
              line-height: 1.846;
              margin-bottom: 5px;
              @include sp {
                font-size: 0.857rem;
              }
            }
          }
          p {
            color: #666;
            font-size: 0.857rem;
            line-height: 1.667;
            text-align: center;
            @include sp {
              font-size: 0.786rem;
            }
          }
        }
      }
    }
    .half_wrapper {
      clear: both;
      display: table;
      table-layout: fixed;
      width: 100%;
      height: 370px;
      @include sp {
        display: block;
        height: auto;
      }
      section {
        position: relative;
        display: table-cell;
        vertical-align: middle;
        width: 50%;
        background-repeat: no-repeat;
        background-position: 50% 100%;
        background-size: cover;
        @include sp {
          display: block;
          width: 100%;
          height: 0;
          padding-top: 60%;
        }
        h2 {
          @include sp {
            position: absolute;
            left: 50%;
            top: 50%;
            -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
          }
        }
        &.works {
          background-image: url('../images/home_works.jpg');
        }
        &.about {
          background-image: url('../images/home_about.jpg');
        }
        a {
          position: absolute;
          display: block;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          &:hover {
            background-color: rgba(255,255,255,0.2);
          }
        }
      }
    }
    .blog {
      padding-top: 100px;
      padding-bottom: 100px;
      @include tab {
        padding-top: 60px;
        padding-bottom: 60px;
      }
      @include sp {
        padding-top: 40px;
        padding-bottom: 40px;
      }
      .inner {
        @include row;
      }
      h2 {
        float: left;
        width: 25%;
        margin-bottom: 30px;
        @include sp {
          float: none;
          width: 100%;
          margin-bottom: 40px;
        }
      }
      .readmore_wrapper {
        float: left;
        width: 25%;
        @include sp {
          float: none;
          width: 100%;
        }
      }
      .readmore {
        width: 130px;
        height: 38px;
        line-height: 36px;
        border-radius: 19px;
      }
      .blog_wrapper {
        @include tab_pc {
          min-height: 90px;
        }
      }
      table {
        float: right;
        width: 75%;
        @include sp {
          float: none;
          width: 100%;
          margin-bottom: 30px;
        }
        tr {
          th {
            white-space: nowrap;
            vertical-align: top;
            text-align: left;
            @include sp {
              display: block;
              margin-bottom: 10px;
            }
            .date {
              margin: 0;
              color: #333;
              font-size: 1rem;
              font-weight: 400;
              line-height: 26px;
            }
            .category {
              display: inline-block;
              margin: 0 15px;
              width: 100px;
              height: 26px;
              line-height: 26px;
              font-size: 0.857rem;
              text-align: center;
              background-color: $indigo;
              color: #FFF;
            }
          }
          td {
            width: 100%;
            vertical-align: top;
            font-size: 1.071rem;
            line-height: 26px;
            text-align: left;
            @include sp {
              display: block;
              font-size: 1rem;
              line-height: 1.75;
            }
            a {
              color: #333;
              &:hover {
                color: #666;
              }
            }
          }
          &:not(:last-of-type) {
            td {
              padding-bottom: 25px;
              @include sp {
                padding-bottom: 15px;
              }
            }
          }
        }
      }
    }
  }
}
