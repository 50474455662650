@charset "UTF-8";

.page-contact {
  .main {
    padding-top: 80px;
    padding-bottom: 80px;
    @include tab {
      padding-top: 50px;
      padding-bottom: 60px;
    }
    @include sp {
      padding-top: 30px;
      padding-bottom: 40px;
    }
    .about {
      margin-bottom: 80px;
      @include tab {
        margin-bottom: 60px;
      }
      @include sp {
        margin-bottom: 40px;
      }
  		p {
        font-size: 1.286rem;
        line-height: 2;
        text-align: center;
        @include sp {
          font-size: 1.143rem;
        }
  		}
    }
    .tel {
      margin-bottom: 80px;
      @include tab {
        margin-bottom: 60px;
      }
      @include sp {
        margin-bottom: 40px;
      }
  		h2 {
        margin-bottom: 35px;
        @include sp {
          margin-bottom: 30px;
        }
  		}
  		.num {
        font-size: 2.143rem;
        font-weight: 700;
        text-align: center;
        margin: 0 auto;
        a {
          color: #000;
        }
        @include sp {
          font-size: 1.786rem;
        }
  		}
    }
    .mail {
      width: 100%;
      max-width: 880px;
      margin: 0 auto;
  		h2 {
        margin-bottom: 40px;
  		}
      .thanks {
        margin-bottom: 50px;
        font-size: 1.286rem;
        line-height: 2;
        text-align: center;
        @include tab {
          margin-bottom: 40px;
        }
        @include sp {
          margin-bottom: 30px;
          font-size: 1.143rem;
        }
      }
  		.notice {
        margin-bottom: 50px;
        background-color: #F5F5F5;
        padding: 25px;
        @include sp {
          font-size: 0.857rem;
          padding: 20px 15px;
          margin-bottom: 30px;
        }
  			p {
          color: #333;
          font-size: 1rem;
          line-height: 2;
  			}
  		}
  		.form {
  			.note {
          font-size: 1rem;
          margin-bottom: 15px;
          @include sp {
            margin-bottom: 25px;
          }
  			}
  			table {
          width: 100%;
          border-collapse: collapse;
          border-top: 1px solid #E5E5E5;
          margin-bottom: 70px;
          @include tab {
            margin-bottom: 55px;
          }
          @include sp {
            margin-bottom: 40px;
          }
					tr {
            &.method {
              td {
                .mwform-checkbox-field {
                  &:not(:last-of-type) {
                    margin-right: 120px;
                    @include tab {
                      margin-right: 60px;
                    }
                    @include sp {
                      margin-right: 35px;
                    }
                  }
                }
              }
            }
            &.privacy {
              td {
                p {
                  margin-bottom: 10px;
                }
              }
            }
						th {
              padding: 15px 100px 15px 20px;
              font-size: 1rem;
              line-height: 2;
              text-align: left;
              white-space: nowrap;
              vertical-align: middle;
              border-bottom: 1px solid #E5E5E5;
              @include sp {
                display: block;
                padding: 15px 0 5px;
                border-bottom: none;
                font-size: 1.071rem;
              }
              &.vtop {
                vertical-align: top;
              }
						}
						td {
              padding: 15px 45px 15px 0;
              width: 100%;
              line-height: 2;
              text-align: left;
              vertical-align: top;
              border-bottom: 1px solid #E5E5E5;
              @include sp {
                display: block;
                padding: 0 0 20px;
                line-height: 1.64;
              }
						}
						label {
              cursor: pointer;
						}
						input[type="text"], input[type="email"], textarea {
              display: block;
              background-color: #F5F5F5;
              width: 100%;
              @include sp {
                padding: 0.4rem;
              }
						}
						p {
						}
					}
  			}
  			.submit {
  				input, a {
            display: block;
            margin: 0 auto;
            width: 290px;
            height: 48px;
            line-height: 46px;
            border-radius: 24px;
            border: 1px solid $gold;
            background-color: #FFF;
            color: $gold;
            transition: all 0.1s linear;
            text-align: center;
            @include sp {
              width: 155px;
              height: 38px;
              line-height: 36px;
              border-radius: 18px;
            }
            &:hover {
              background-color: $gold;
              color: #FFF;
            }
  				}
  			}
  		}
    }
  }
}
