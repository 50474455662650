@charset "UTF-8";

// Typography
@import '//fonts.googleapis.com/css?family=Lato:400,700';
@mixin kerning {
    font-feature-settings: "palt" 1;
}

// Color
$black       : #000000;
$white       : #FFFFFF;
$gold        : #B57E2E;
$indigo      : #044463;
$gray_indigo : #094664;
$dark_indigo : #002E44;


// Media Queries
@mixin sp_s {
    @media screen and (max-width: 375px) {
        @content;
    }
}
@mixin sp {
    @media screen and (max-width: 640px) {
        @content;
    }
}
@mixin sp_tab {
    @media screen and (max-width: 1000px) {
        @content;
    }
}
@mixin tab {
    @media screen and (min-width: 641px) and (max-width: 1000px) {
        @content;
    }
}
@mixin tab_pc {
    @media screen and (min-width: 641px) {
        @content;
    }
}
@mixin pc {
    @media screen and (min-width: 1001px) {
        @content;
    }
}

// Mixins
@mixin sp_show {
    @include pc {
        display: none !important;
    }
    @include tab {
        display: none !important;
    }
}
@mixin sp_show {
    @include pc {
        display: none !important;
    }
    @include tab {
        display: none !important;
    }
}
@mixin sp_hide {
    @include sp {
        display: none !important;
    }
}

/* SPで表示, PCで非表示 */
.sp_show {
    @include sp_show;
}

/* SPで非表示, PCで表示 */
.sp_hide {
    @include sp_hide;
}

p {
    &.alignright {
        text-align: right;
    }
}
